import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor() { }
  public ngOnInit() {
   
    this.loadScript('../../../assets/plugins/chartist/dist/chartist.min.js');
    this.loadScript('../../../assets/js/custom/chart/dashboard-chart.js');
    // this.loadScript('../../../assets/plugins/chartist/dist/chartist.min.js');
    this.loadScript('../../../assets/plugins/chart.js/dist/Chart.bundle.min.js');

    }
public loadScript(url) {
    //console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
 }
}