import {sequence, trigger, stagger, animate, style, group, query as q, transition, keyframes, animateChild} from '@angular/animations';
const query = (s,a,o={optional:true})=>q(s,a,o);

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(':enter, :leave', style({ position: 'absolute', left:0, right:0, width:'100%' })),
    query(':enter', [style({ opacity: 0 })],
       { optional: true }),
    sequence([
      query(':leave', animateChild()), 
      group([
        query(':leave',
      [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
      { optional: true }
    ),
        query(':enter',
      [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
      { optional: true }
    )
      ]),
      query(':enter', animateChild()),
    ])
  ])
]);




// import {
//   trigger,
//   animate,
//   transition,
//   style,
//   query
// } from '@angular/animations';

// export const fadeAnimation = trigger('fadeAnimation', [
//   transition('* => *', [
//     query(
//       ':enter',
//       [style({ opacity: 0 })],
//       { optional: true }
//     ),
//     query(
//       ':leave',
//       [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
//       { optional: true }
//     ),
//     query(
//       ':enter',
//       [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
//       { optional: true }
//     )
//   ])
// ]);
