import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MasterpageComponent } from './masterpage/masterpage.component';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './routings/app-routing.module';
import { RouterModule } from "@angular/router";
import { DashboardComponent } from './views/dashboard/dashboard.component';


@NgModule({
  declarations: [
    MasterpageComponent,
    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule
  ],
  providers: [],
  bootstrap: [MasterpageComponent]
})
export class AppModule { }
