import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardComponent } from '../views/dashboard/dashboard.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'settings',
    loadChildren: 'src/app/views/settings/organization/organization.module#OrganizationModule'
  },
  // {
  //   path: 'settings',
  //   loadChildren: 'src/app/views/settings/settings-addresses/settings-addresses.module#SettingsAddressesModule'
  // },

  {
    path: 'settings',
    loadChildren: 'src/app/views/settings/companies/companies.module#CompaniesModule'
  },
  {
    path: 'custom-fields',
    loadChildren: 'src/app/views/custom-fields/custom-field/custom-field.module#CustomFieldModule'
  },
  {
    path: 'custom-fields',
    loadChildren: 'src/app/views/custom-fields/field-data/field-data.module#FieldDataModule'
  },
  {
    path: 'custom-fields',
    loadChildren: 'src/app/views/custom-fields/form-data/form-data.module#FormDataModule'
  },
  {
    path: 'custom-fields',
    loadChildren: 'src/app/views/custom-fields/custom-fields-modules/custom-fields-module.module#CustomFieldsModuleModule'
  },
  {
    path: 'custom-fields',
    loadChildren: 'src/app/views/custom-fields/modules-screens/modules-screens.module#ModulesScreensModule'
  },
  {
    path: 'custom-fields',
    loadChildren: 'src/app/views/custom-fields/visible-to/visible-to.module#VisibleToModule'
  },
  {
    path: 'Chart-Of-Accounts',
    loadChildren: 'src/app/views/Chart-Of-Accounts/currency/currency.module#CurrencyModule'
  },
  {
    path: 'Chart-Of-Accounts',
    loadChildren: 'src/app/views/Chart-Of-Accounts/coa/coa.module#COAModule'
  },
  {
    path: 'Chart-Of-Accounts',
    loadChildren: 'src/app/views/Chart-Of-Accounts/accounts-taxes/accounts-taxes.module#AccountsTaxesModule'
  },
  {
    path: 'Chart-Of-Accounts',
    loadChildren: 'src/app/views/Chart-Of-Accounts/parent-account-type/parent-account-type.module#ParentAccountTypeModule'
  },
  {
    path: 'Chart-Of-Accounts',
    loadChildren: 'src/app/views/Chart-Of-Accounts/accounts-account-types/accounts-account-types.module#AccountsAccountTypesModule'
  },
  {
    path: 'Chart-Of-Accounts',
    loadChildren: 'src/app/views/Chart-Of-Accounts/accounts-numbering/accounts-numbering.module#AccountsNumberingModule'
  },
  {
    path: 'Chart-Of-Accounts',
    loadChildren: 'src/app/views/Chart-Of-Accounts/accounts-transactions-details/accounts-transactions-details.module#AccountsTransactionsDetailsModule'
  },
  {
    path: 'Chart-Of-Accounts',
    loadChildren: 'src/app/views/Chart-Of-Accounts/transactions/transactions.module#TransactionsModule'
  },
  {
    path: 'custom-fields',
    loadChildren: 'src/app/views/custom-fields/custom-field-cf/custom-field-cf.module#CustomFieldCFModule'
  },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, 
      {preloadingStrategy: PreloadAllModules, useHash: true }),
  ],
  declarations: [],
  providers:[
    
  ]
})
export class AppRoutingModule { }
